.App {
  text-align: center;
  font-family: Times-new-roman;
}

#images-wrapper {    
   line-height: 0;       
    -webkit-column-count: 3;    
    -webkit-column-gap: 10px;    
    -moz-column-count: 3;
    -moz-column-gap: 10px;
     column-count: 3;    
     column-gap: 10px;    
	 margin-top: 10px;
}  

#images-wrapper img {    
   width: 100% !important;    
   height: auto !important;  
   margin-bottom: 10px;
}  
#images-wrapper{    
   display:inline-block;    
   margin-right: auto;    
   margin-left: auto;  
}

@media (max-width: 1000px) {
   #images-wrapper {
   -moz-column-count:    3;
   -webkit-column-count: 3;
   column-count:         3;
   }
  }  
@media (max-width: 800px) {
   #images-wrapper {
   -moz-column-count:    2;
   -webkit-column-count: 2;
   column-count:         2;
   }
  }  
@media (max-width: 400px) {
   #images-wrapper {
   -moz-column-count:    1;
   -webkit-column-count: 1;
    column-count:        1;
   }
  }

.gallery {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.contact-info {
	justify-content: center;
	margin-right: 3rem;
}

.width100 {
	width: 50% !important;
}

.copyright {
	text-align: center;
	width: 100vw;
}

.banner {
	flex-direction: row !important;
	justify-content: space-evenly !important;
	font-size: 24px;
	color: white;
}

.contact {
	max-width: 30%;
	width: 30%;
	text-align: start;
	justify-content: space-evenly;
}

.profile {
	width: 50%;
}

.about {
	max-width: 30%;
	width: 30%;
	text-align: start;
}

.flex-col {
	display: flex;
	flex-direction: column;
	width: inherit;
}

.fit-content {
	width: fit-content;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.profile-picture {
	width: 100%;
}

.t-align-start {
	text-align: start;
	margin-left: 9rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: Black;
}

.t-white {
	color: white;
}

.App-link {
  color: #61dafb;
}

.header-banner {
	width: 100vw;
	height: 100vh;
}

.img-box {
	width: 100vw;
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: 40%;
	justify-content: end;
	flex-direction: column;
	align-items: center;
}

.img-border {
	border: solid 1vw white;
}

.meny {
	position: absolute;
	top: 0;
	width: 100vw;
	height: 8vh;
	align-content: center;
}

.icon {
	width: 25px;
}

.button {
	background: white;
	height: 75px;
	width: 300px;
	margin: 0 0 3rem 0;
	align-content: center;
	text-align: center;
	font-size: medium;
}

.menuTitle {
	margin: 0px;
}

.flex {
	display: flex;
}

.pAbsolute {
	position: absolute;
	right: 1px;
}

.pAbsolute a {
	marign: 0 0 0 2rem;
}

.w100 {
	width: 100vw;
}

.center {
	display: flex;
	justify-content: center;
}

.padding-right {
	padding-right: 2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
